.app {
    background: #f0f4ff;

    .ant-layout-header {
        background: transparent;
        margin-top: 20px;

        .logo-wrapper {
            display: inline-block;

            .logo {
                width: 200px;
            }
        }

        .ant-anchor-wrapper {
            display: inline-block;
            list-style: none;
            vertical-align: middle;
            margin-bottom: 0;

            .menu {
                display: inline-block;
                list-style: none;
                vertical-align: middle;
                margin-bottom: 0;

                li {
                    float: left;
                    width: 80px;
                    text-align: center;
                    font-weight: bold;
                    cursor: pointer;
                    line-height: 30px;

                    a {
                        color: #333;
                        padding-bottom: 5px;
                        border-bottom: 3px transparent solid;
                    }

                    a:hover {
                        border-bottom: 3px #e91e63 solid;
                    }
                }
            }
        }
    }

    .ant-layout-content {
        .introduction {
            margin-top: 50px;
            text-align: center;

            .paragraph-one {
                font-size: 48px;
                font-weight: bold;
                line-height: 60px;
            }

            .paragraph-two {
                font-size: 30px;
                line-height: 48px;
            }
        }

        .trial {
            margin-top: 50px;
            text-align: center;

            .ant-btn {
                height: 50px;
                font-size: 24px;
                line-height: 24px;
                padding: 5px 30px;
                
                .ant-anchor-link {
                    padding: 0;

                    a {
                        color: #fff;
                    }
                }
            }
        }

        .support {
            margin-top: 15px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            color: #666;

            .text {
                display: inline-block;
            }

            .platform-list {
                display: inline-block;
                list-style: none;
                vertical-align: middle;
                margin-bottom: 0;
                padding-left: 0;

                li {
                    float: left;
                    margin-left: 15px;
                    text-align: center;

                    .anticon {
                        margin-right: 5px;
                        color: #03a9f4;
                    }
                }
            }
        }

        .description {
            margin-top: 50px;

            .carousel-title {
                width: 1200px;
                margin: 15px auto;
                display: flex;

                .title-container {
                    flex: 1;
                    text-align: center;
                    font-weight: bold;
                    font-size: 24px;
                    color: #666;

                    .title-block {
                        display: inline-block;
                        cursor: pointer;

                        .anticon {
                            margin-right: 5px;
                            color: #607d8b;
                        }
                    }

                    .title-block.active {
                        .anticon {
                            margin-right: 5px;
                            color: #e91e63;
                        }
                    }
                }
            }

            .ant-carousel {
                width: 1200px;
                margin: 0 auto;

                .carousel-image {
                    border-radius: 30px;
                }
            }
        }

        .download {
            margin-top: 50px;
            text-align: center;

            .title {
                color: #333;
                font-size: 36px;
                line-height: 36px;
                font-weight: bold;

                .tip {
                    color: #2196f3;
                }
            }

            .sub-title {
                margin-top: 15px;
                font-size: 16px;
                color: #999;
            }

            .platform-list {
                width: 1200px;
                margin: 50px auto;
                display: flex;

                .card-container {
                    flex: 1;

                    .ant-card {
                        margin: 0 auto;
                        border-radius: 30px;
                        width: 150px;
                        height: 150px;

                        .ant-card-body {
                            padding: 0;

                            .anticon {
                                color: #72c0ff;
                                font-size: 48px;
                                line-height: 150px;
                            }
                        }
                    }

                    .ant-card:hover {
                        .anticon {
                            color: #2196f3;
                        }
                    }
                }

                .title {
                    margin-top: 10px;
                    margin-bottom: 0;
                    line-height: 30px;
                    font-size: 16px;
                    color: #333;
                }
            }
        }

        .contact {
            text-align: center;

            .title {
                color: #333;
                font-size: 36px;
                line-height: 36px;
                font-weight: bold;
            }

            .contact-image {
                margin-top: 15px;
                width: 400px;
            }
        }
    }

    .ant-layout-footer {
        color: #999;
        font-size: 16px;
        font-style: italic;
        font-weight: bold;
        font-family: cursive;
        text-align: right;
    }
}